import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Switch, Redirect } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Navbar3 from './Navbar3'
import Dashboard from '../dashboard/index'
import Watchlist from '../watchlist/index'
import News from '../news/index'
import Orderbook from '../myorders/index'
//import MyTradeBook from '../mytrades/index'
//import Margin from '../margin/index'
//import InfoPage from '../infoscreens/index'
//import ReportsPage from '../reports/index'
import "chartiq/css/normalize.css";
import "chartiq/css/stx-chart.css";
// import "chartiq/css/chartiq.css";
import NetPositionsPage from '../netpositions/index'
//import ProfilePage from '../user/ProfilePage'
import { FooterActions } from '../../../common/constants'
import { ModuleKeys, orderEntryTypes, localStorageKeys } from '../../../common/constants'
import { showOrderDialog, toggleOrderDialog } from '../../../actions/orderentry/orderentryaction'
import { onSessionTimeout } from '../../../actions/common'
import { setFooterWidget } from '../../../actions/dashboard'
import NotificationsPage from '../notifications/index'
import Products from '../products/index'
import '../../../common/socketwrapper'
import '../../../common/socketwrapperTR'
import AutoLogout from '../../../router/AutoLogout'
import KeyShortcuts from './KeyShortcuts'
import { setWatchlistExpandWidget } from '../../../actions/dashboard';
import OrderEntryWindow from '../../../components/orderentry/OrderEntryWindow'
import asyncComponent from '../../../common/asynccomponent'
import MyTradingPlan from '../../../components/MyTradingPlan/MyTradingPlan'
import { setCookie } from '../../../common/utils';
import PoaPledge from '../../../components/PoaPledge/PoaPledge';
import ExchangePledge from '../../../components/ExchangePledge/ExchangePledge';
import { getItemByKey } from '../../../common/utils'
const MarketStats = asyncComponent(() => import("../marketstats/index"));
const Charts =  asyncComponent(() => import("../charts/index"));
const ReportsPage = asyncComponent(() => import("../reports/index"));
const InfoPage = asyncComponent(() => import("../infoscreens/index"));
const MyTradeBook = asyncComponent(() => import("../mytrades/index"));
const Margin = asyncComponent(() => import("../margin/index"));
const ProfilePage = asyncComponent(() => import("../user/ProfilePage")); 
const Scripdetails = asyncComponent(() => import("../scripdetails/index")); 

class PostLoginHome extends Component {

    constructor(props) {
        super(props);
        this.localStorageEvent = this.localStorageEvent.bind(this);
        var revocResDetail = getItemByKey(localStorageKeys.EDIS_REVOC_RESPONSE);
        console.log(revocResDetail);
        if(revocResDetail !== undefined && revocResDetail !== null && revocResDetail !== "" && revocResDetail !== "null"){
                     this.props.history.push("/home/dpholdings");
          }
    }

    executeFooterActions(action) {
        switch (action) {
            case FooterActions.MY_ORDERS:
                this.props.history.push("/home/myorders");
                this.props.setFooterWidget(ModuleKeys.FOOTER_WIDGET)
                break;
            case FooterActions.MY_TRADES:
                this.props.history.push("/home/mytrades");
                this.props.setFooterWidget(ModuleKeys.FOOTER_WIDGET)
                break;
            case FooterActions.NET_POSITION:
                this.props.history.push("/home/netpositions");
                this.props.setFooterWidget(ModuleKeys.FOOTER_WIDGET)
                break;
            case FooterActions.MARGIN:
                this.props.history.push("/home/margin");
                this.props.setFooterWidget(ModuleKeys.FOOTER_WIDGET)
                break;
            case FooterActions.MYTRADINGPLAN:
                // <Redirect to="/home/mytradingplan" />
                this.props.history.push("/home/mytradingplan");
                this.props.setFooterWidget(ModuleKeys.FOOTER_WIDGET)
                break;
            case FooterActions.WATCHLIST:
                this.props.setWatchlistExpandWidget(ModuleKeys.ALL, { prevWidget: undefined });
                this.props.history.push("/home/watchlist");
                break;
            case FooterActions.PLACE_ORDER:
                this.props.showOrderDialog({ orderentrytype: orderEntryTypes.NEW }); // empty orderdetails to reset previous inputs
                break;
            default:break;
        }
    }

    onSessionTimeout() {
        this.props.onSessionTimeout();
    }

    componentWillMount() {
        window.socketEvents.disconnect();
        window.socketEventsTR.disconnect();
        window.addEventListener("storage", this.localStorageEvent);
        setCookie("loginResponsive1", "responsive", 9999999999999999);
    }

    localStorageEvent(event) {
        // if (event.key === localStorageKeys.ACCOUNT_ID && window.localStorage.getItem(localStorageKeys.ACCOUNT_ID) && window.localStorage.getItem(localStorageKeys.ACCOUNT_ID) !== "") {
        //     window.location.reload();
        // }
        if (event.key === localStorageKeys.ACCOUNT_ID && window.sessionStorage.getItem(localStorageKeys.ACCOUNT_ID) && window.sessionStorage.getItem(localStorageKeys.ACCOUNT_ID) !== "") {
            window.location.reload();
        }
    }

    componentDidMount() {
        window.socketEvents.oninit();
        window.socketEventsTR.oninit();
    }

    componentWillUnmount() {
        window.socketEvents.disconnect();
        window.socketEventsTR.disconnect();
        window.removeEventListener("storage", this.localStorageEvent);
    }


    render() {
        return (
            <AutoLogout {...this.props} onSessionTimeout={this.onSessionTimeout.bind(this)}>
                <KeyShortcuts {...this.props}>
                    <div className="allcontentholder" >
                        <OrderEntryWindow {...this.props}/>
                        <div className="navbar-fixed-top">
                            <Header history={this.props.history} />
                            <Navbar3 match={this.props.match} history={this.props.history} />
                        </div>
                        <div className={"appcontent " + (`${this.props.match.path}/scripdetails` === this.props.location.pathname ? "scrip-padding" : "")}>
                            <Switch>
                                <Route path={`${this.props.match.path}/dashboard`} exact component={() => <Dashboard history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/watchlist`} exact component={() => <Watchlist />} />

                                {/*news route */}
                                <Route path={`${this.props.match.path}/news`} component={News} />
                                {/* <Route path={`${this.props.match.path}/news/hotnews`} exact component={() => <News component='hotnews' />} /> */}

                                <Route path={`${this.props.match.path}/charts`} component={Charts} />

                                <Route path={`${this.props.match.path}/myorders`} component={Orderbook} />

                                <Route path={`${this.props.match.path}/mytrades`} component={MyTradeBook} />
                                <Route path={`${this.props.match.path}/margin`} component={Margin} />
                                <Route path={`${this.props.match.path}/netpositions`} component={NetPositionsPage} />
                                <Route path={`${this.props.match.path}/mytradingplan`} component={() => <MyTradingPlan flag='0' history={this.props.history}/>} />
            
                                <Route path={`${this.props.match.path}/profile`} exact component={() => <ProfilePage page='profile' />} />
                                <Route path={`${this.props.match.path}/fundtransfer`} exact component={() => <ProfilePage page='fundtransfer' history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/dpholdings`} exact component={() => <ProfilePage page='dpholdings' history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/settings`} exact component={() => <ProfilePage page='settings' history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/PoaPledge`} exact component={() => <PoaPledge page='pledgeunpledge' history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/ExchangePledge`} exact component={() => <ExchangePledge page='exchangePledgeUnpledge' history={this.props.history} />} />
                                

                                <Route path={`${this.props.match.path}/ipo`} exact component={() => <Products page='ipo' />} />
                                <Route path={`${this.props.match.path}/sep`} exact component={() => <Products page='sep' history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/ofs`} exact component={() => <Products page='ofs' />} />
                                <Route path={`${this.props.match.path}/basketorder`} exact component={() => <Products page='basketorder' />} />

                                <Route path={`${this.props.match.path}/portfoliotracker`} exact component={() => <Products page='portfolioTracker' history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/portfolioTrackerDion`} exact component={() => <Products page='portfolioTrackerDion'  history={this.props.history} />} />
                               
                                <Route path={`${this.props.match.path}/scripdetails`} exact component={() => <Scripdetails history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/aboutus`} exact component={() => <InfoPage page='aboutus' />} />
                                <Route path={`${this.props.match.path}/contactus`} exact component={() => <InfoPage page='contactus' />} />
                                <Route path={`${this.props.match.path}/termsandcondition`} exact component={() => <InfoPage page='termsandcondition' />} />
                                <Route path={`${this.props.match.path}/importantlinks`} exact component={() => <InfoPage page='importantlinks' />} />
                                <Route path={`${this.props.match.path}/bhavcopy`} exact component={() => <InfoPage page='bhavcopy' />} />
                                <Route path={`${this.props.match.path}/exchangemessages`} exact component={() => <InfoPage page='exchangemessages' />} />
                                <Route path={`${this.props.match.path}/corporateaction`} exact component={() => <InfoPage page='corporateaction' />} />

                                <Route path={`${this.props.match.path}/loginalert`} exact component={() => <InfoPage page='loginalert' />} />
                                <Route path={`${this.props.match.path}/faq`} exact component={() => <InfoPage page='faq' />} />
                                <Route path={`${this.props.match.path}/markettimings`} exact component={() => <InfoPage page='markettimings' />} />


                                <Route path={`${this.props.match.path}/margincalculator`} exact component={() => <InfoPage page='margincalculator' />} />
                                <Route path={`${this.props.match.path}/spancalculator`} exact component={() => <InfoPage page='spancalculator' />} />
                                <Route path={`${this.props.match.path}/optioncalculator`} exact component={() => <InfoPage page='optioncalculator' />} />
                                <Route path={`${this.props.match.path}/costofcarrycalculator`} exact component={() => <InfoPage page='costofcarrycalculator' />} />
                                <Route path={`${this.props.match.path}/help`} exact component={() => <InfoPage page='help' />} />

                                <Route path={`${this.props.match.path}/equity`} exact component={() => <ReportsPage page='equity' />} />
                                <Route path={`${this.props.match.path}/fno`} exact component={() => <ReportsPage page='fno' />} />
                                <Route path={`${this.props.match.path}/ledger`} exact component={() => <ReportsPage page='ledger' />} />
                                <Route path={`${this.props.match.path}/digitalcontractnote`} exact component={() => <ReportsPage page='digitalcontractnote' />} />
                                <Route path={`${this.props.match.path}/backofficereportcurrent`} exact component={() => <ReportsPage page='backofficecurrrent' />} />
                                <Route path={`${this.props.match.path}/backofficereportprevfinancialyear`} exact component={() => <ReportsPage page='backofficereportprevfinancialyear' />} />
                                <Route path={`${this.props.match.path}/transaction`} exact component={() => <ReportsPage page='transaction' />} />
                                <Route path={`${this.props.match.path}/dematholdings`} exact component={() => <ReportsPage page='dematholdings' />} />
                                <Route path={`${this.props.match.path}/clientmaster`} exact component={() => <ReportsPage page='clientmaster' />} />
                                <Route path={`${this.props.match.path}/stt`} exact component={() => <ReportsPage page='stt' />} />
                                <Route path={`${this.props.match.path}/brokerage`} exact component={() => <ReportsPage page='brokerage' />} />
                                <Route path={`${this.props.match.path}/sepregistrationreport`} exact component={() => <ReportsPage page='sepregistrationreport' history={this.props.history} />} />
                                <Route path={`${this.props.match.path}/sepexecutionreport`} exact component={() => <ReportsPage page='sepexecutionreport' />} />
                                <Route path={`${this.props.match.path}/report/capitalgain`} exact component={() => <ReportsPage page='capitalgain' />} />
                                <Route path={`${this.props.match.path}/report/gainsummary`} exact component={() => <ReportsPage page='gainsummary' />} />
                                
                                
                                <Route path={`${this.props.match.path}/notifications`} component={NotificationsPage} />

                                <Route path={`${this.props.match.path}/ms/eq/overview`} exact component={() => <MarketStats page='eq_overview' />} />
                                <Route path={`${this.props.match.path}/ms/eq/mkt/summary`} exact component={() => <MarketStats page='eq_mkt_summary' />} />
                                <Route path={`${this.props.match.path}/ms/eq/mkt/sectors`} exact component={() => <MarketStats page='eq_mkt_sectors' />} />
                                <Route path={`${this.props.match.path}/ms/eq/mkt/fiidiiactivity`} exact component={() => <MarketStats page='eq_mkt_fiidiiactivity' />} />
                                <Route path={`${this.props.match.path}/ms/eq/mkt/advancedecline`} exact component={() => <MarketStats page='eq_mkt_advancedecline' />} />
                                <Route path={`${this.props.match.path}/ms/eq/mkt/intradaydeals`} exact component={() => <MarketStats page='eq_mkt_intradaydeals' />} />
                                <Route path={`${this.props.match.path}/ms/eq/mkt/screeners`} exact component={() => <MarketStats page='eq_mkt_screeners' />} />
                                <Route path={`${this.props.match.path}/ms/eq/heatmap`} exact component={() => <MarketStats page='eq_heatmap' />} />
                                <Route path={`${this.props.match.path}/ms/eq/indices`} exact component={() => <MarketStats page='eq_indices' />} />
                                <Route path={`${this.props.match.path}/ms/eq/calender`} exact component={() => <MarketStats page='eq_calender' />} />
                                <Route path={`${this.props.match.path}/ms/fno/overview`} exact component={() => <MarketStats page='fno_overview' />} />
                                <Route path={`${this.props.match.path}/ms/fno/mkt/summary`} exact component={() => <MarketStats page='fno_mkt_summary' />} />
                                <Route path={`${this.props.match.path}/ms/fno/mkt/sectors`} exact component={() => <MarketStats page='fno_mkt_sectors' />} />
                                <Route path={`${this.props.match.path}/ms/fno/mkt/screeners`} exact component={() => <MarketStats page='fno_mkt_screeners' />} />
                                <Route path={`${this.props.match.path}/ms/fno/mkt/advancedecline`} exact component={() => <MarketStats page='fno_mkt_advancedecline' />} />
                                <Route path={`${this.props.match.path}/ms/fno/mkt/unusual`} exact component={() => <MarketStats page='fno_mkt_unusal' />} />
                                <Route path={`${this.props.match.path}/cur/overview`} exact component={() => <MarketStats page='cur_overview' />} />
                                <Route path={`${this.props.match.path}/com/overview`} exact component={() => <MarketStats page='com_overview' />} />
                                {/* <Route path={`${this.props.match.path}/mutualfund`} component={MutualFund} />
                            <Route path={`${this.props.match.path}/news`} component={News} />
                            <Route path={`${this.props.match.path}/portifolio`} component={Portifolio} />
                            <Route path={`${this.props.match.path}/products`} component={Products} />
                            <Route path={`${this.props.match.path}/reports`} component={Reports} />
                            <Route path={`${this.props.match.path}/research`} component={Research} /> */}
                                <Redirect to="/home/dashboard" />
                            </Switch>
                        </div>
                        <Footer executeFooterActions={this.executeFooterActions.bind(this)} match={this.props.match} />
                    </div>
                </KeyShortcuts>
            </AutoLogout>
        );
    };
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        loginStatus: state.loginStatus.status,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setWatchlistExpandWidget: (activeWidget, statedata) => setWatchlistExpandWidget(activeWidget, statedata, dispatch),
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        toggleOrderDialog: (value) => toggleOrderDialog(dispatch, value),
        onSessionTimeout: () => onSessionTimeout(dispatch),
        setFooterWidget: (activeWidget) => setFooterWidget(activeWidget, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostLoginHome);